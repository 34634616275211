import type { Breadcrumbs } from '@haaretz/s-data-structure-types';

export default function getArticleSectionId(breadcrumbs: Breadcrumbs | null) {
  const sectionId = breadcrumbs?.[1]?.contentId;

  if (!sectionId) {
    console.error('sectionId not found');
    return null;
  }

  return sectionId;
}
