'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import useRenderKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import React from 'react';

import redirectToAdBlockPage from './redirectToAdBlockPage';

import type { PaywallType } from '@haaretz/s-types';

export interface UseDetectAdBlockProps {
  paywallType: PaywallType;
}

const ADS_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

export default function useDetectAdBlock({ paywallType }: UseDetectAdBlockProps) {
  const redirectedRef = React.useRef(false);

  const platform = usePlatform();
  const renderingKind = useRenderKind();
  const user = useUser('cookieValue');

  const isWebPlatform = platform !== 'app';
  const isNotBot = renderingKind !== 'expanded';
  const isNotPremiumContent = paywallType !== 'premium';
  const isNotPaying = user.userType !== 'paying';

  const canRiderectToAdBlockPage =
    isWebPlatform &&
    isNotBot &&
    isNotPremiumContent &&
    isNotPaying &&
    redirectedRef.current === false;

  React.useEffect(() => {
    if (redirectedRef.current === true || canRiderectToAdBlockPage === false) return;

    fetch(ADS_URL, { credentials: 'omit' })
      .catch(
        /* istanbul ignore next */ () => {
          if (canRiderectToAdBlockPage) {
            redirectedRef.current = true;

            redirectToAdBlockPage();
          }
        }
      )
      .then(response => {
        /* istanbul ignore next */
        const isAdBlocked = !response?.ok || response.status !== 200 || response.url !== ADS_URL;

        /* istanbul ignore next */
        if (isAdBlocked && canRiderectToAdBlockPage) {
          redirectedRef.current = true;

          redirectToAdBlockPage();
        }
      });
  }, [canRiderectToAdBlockPage]);

  React.useEffect(() => {
    if (redirectedRef.current === true || canRiderectToAdBlockPage === false) return;

    /*
    `adBanner` is a reference to a bait element in the DOM with the id 'adBanner'.
    `adBanner.offsetParent` returns the closest ancestor in the DOM tree that is a CSS positioned
    element (an element with position absolute, relative, or fixed), or null if the element is
    hidden (display: none) or if the element itself has position: fixed.
    `isAdBlocked` checks if ad blocking is active. If `adBanner` is hidden
    (adBanner.offsetParent === null), it's likely due to an active ad blocker,
    hence `isAdBlocked` is true.
  */
    const adBanner = document.getElementById('adBanner');
    const isAdBlocked = adBanner && adBanner.offsetParent === null;

    /* istanbul ignore else */
    if (canRiderectToAdBlockPage && isAdBlocked) {
      redirectedRef.current = true;

      redirectToAdBlockPage();
    }
  }, [canRiderectToAdBlockPage]);
}
