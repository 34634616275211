'use client';

import config from '@haaretz/l-config';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import useLogout from '@haaretz/s-auth-hooks/use-logout';
import { appInfo, hostname } from '@haaretz/s-consts';
import switchToDomain from '@haaretz/s-url-utils/switchToDomain';
import useBi from '@haaretz/s-use-bi';
import React from 'react';

const IS_ABUSE_URL = '/isabuser';
const LOGIN_URL = switchToDomain('https://login.haaretz.co.il', hostname);

interface AbuseResponse {
  code: number;
  message: string;
  result: `${0 | 1 | 2 | 3}`;
}

function checkUserIsAbuser(data: AbuseResponse) {
  return data.result === '1' || data.result === '2';
}

const ssoDomain = config.get('sso');

export default function useCheckAbuse() {
  const user = useUser('cookieValue');
  const platform = usePlatform();
  const biAction = useBi();

  const redirectUrlRef = React.useRef<string | null>(null);

  const logout = useLogout({
    onSuccess: () => {
      const redirectUrl = redirectUrlRef.current ?? /* istanbul ignore next */ LOGIN_URL;

      if (window.location.origin !== redirectUrl) {
        window.location.assign(redirectUrl);
      }
    },
    onError: error => {
      throw error;
    },
  });

  const isPaying = user.userType === 'paying';

  React.useEffect(() => {
    if (platform !== 'app' && appInfo.isHTZ && isPaying) {
      const timeoutId = setTimeout(() => {
        fetch(`${ssoDomain}${IS_ABUSE_URL}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.json())
          .then((data: AbuseResponse) => {
            if (checkUserIsAbuser(data)) {
              const isNewAbuser = data.result === '1';

              biAction({
                action_id: 53,
                feature: 'abuse',
                campaign_name: 'abuse logout',
                campaign_details: isNewAbuser ? 'new abuser' : 'another device',
              });

              const redirectUrl = `${LOGIN_URL}${
                isNewAbuser
                  ? `/abuse?loginData=${window.btoa(JSON.stringify({ email: user.userMail }))}`
                  : ''
              }`;

              redirectUrlRef.current = redirectUrl;

              logout.mutate({});
            }
          })
          .catch(err => {
            console.error(`Error in useCheckAbuse: ${(err as Error).message}`);
          });
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return undefined;
  }, [biAction, isPaying, logout, platform, user.anonymousId, user.userMail]);
}
