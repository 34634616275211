'use client';

import useCheckAbuse from '@haaretz/s-article-hooks/useCheckAbuse';
import useDetectAdBlock from '@haaretz/s-article-hooks/useDetectAdBlock';
import useNativeAppInitialization from '@haaretz/s-article-hooks/useNativeAppInitialization';
import { useSetArticleId } from '@haaretz/s-atoms/articleId';
import { useSetAuthors } from '@haaretz/s-atoms/authors';
import { useSetCookie } from '@haaretz/s-atoms/cookie';
import { useSetPage } from '@haaretz/s-atoms/page';
import { useSetPageStatusToken } from '@haaretz/s-atoms/pageStatusToken';
import { useSetPageType } from '@haaretz/s-atoms/pageType';
import { useSetPaywallType } from '@haaretz/s-atoms/paywallType';
import { useSetPreviewId } from '@haaretz/s-atoms/previewId';
import { useSetRenderingKind } from '@haaretz/s-atoms/renderingKind';
import { useSetTags } from '@haaretz/s-atoms/tags';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import getArticleSectionId from '@haaretz/s-server-data-fetchers/getArticleSectionId';
import useBackToSection from '@haaretz/s-use-back-to-section';
import useBi from '@haaretz/s-use-bi';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import React from 'react';

import ProvokeAdBlock from '../ProvokeAdBlock';

import type { UseDetectAdBlockProps } from '@haaretz/s-article-hooks/useDetectAdBlock';
import type { BiDataOverrides, Breadcrumbs } from '@haaretz/s-data-structure-types';
import type { AuthorPageFragment } from '@haaretz/s-fragments/AuthorPage';
import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';
import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';
import type { PageType, RenderingKind, PaywallType } from '@haaretz/s-types';

const requestBiData: BiDataOverrides = {};

interface CommonUtilsProps extends UseDetectAdBlockProps {
  articleId: string | null;
  pageType: PageType;
  previewId: string | null;
  paywallType: PaywallType;
  renderingKind: RenderingKind;
  authors: Pick<AuthorPageFragment, 'contentId' | 'name'>[] | null;
  pageStatusToken: string | null;
  tags: Pick<TagPageFragment, 'contentId' | 'name'>[] | null;
  pageContentId: CommonPageLayoutFragment['contentId'];
  breadcrumbs: Breadcrumbs | null;
  backButtonEnabled: boolean | null | undefined;
  backButtonNumberOfVisits: number | null | undefined;
  backButtonSectionUrl: string | null | undefined;
  backButtonUserType: string[] | null | undefined;
}

export default function CommonUtils({
  pageContentId,
  pageType,
  paywallType,
  renderingKind,
  previewId,
  authors,
  articleId,
  pageStatusToken,
  tags,
  breadcrumbs,
  backButtonEnabled,
  backButtonNumberOfVisits,
  backButtonSectionUrl,
  backButtonUserType,
}: Readonly<CommonUtilsProps>) {
  const biRequest = useBi('pageview');

  useNativeAppInitialization();
  useDetectAdBlock({ paywallType });

  useBackToSection({
    enabled: backButtonEnabled,
    sectionUrl: backButtonSectionUrl,
    numberOfVisits: backButtonNumberOfVisits,
    userType: backButtonUserType,
  });

  useCheckAbuse();

  const setPageType = useSetPageType();
  const setPagePaywallLevel = useSetPaywallType();
  const setRenderingKind = useSetRenderingKind();
  const setAuthors = useSetAuthors();
  const setTags = useSetTags();
  const setPage = useSetPage();
  const setCookie = useSetCookie();
  const setPreviewId = useSetPreviewId();
  const setArticleId = useSetArticleId();
  const setPageStatusToken = useSetPageStatusToken();
  const isLoaded = useLoadEvent();

  React.useEffect(() => {
    setPageType(pageType);
  }, [pageType, setPageType]);

  React.useEffect(() => {
    setPagePaywallLevel(paywallType);
  }, [paywallType, setPagePaywallLevel]);

  React.useEffect(() => {
    setRenderingKind(renderingKind);
  }, [renderingKind, setRenderingKind]);

  React.useEffect(() => {
    setAuthors(authors || null);
  }, [authors, setAuthors]);

  React.useEffect(() => {
    setTags(tags || null);
  }, [tags, setTags]);

  React.useEffect(() => {
    setPageStatusToken(pageStatusToken);
  }, [pageStatusToken, setPageStatusToken]);

  React.useEffect(() => {
    setPage({
      pageContentId,
      articleSectionId: getArticleSectionId(breadcrumbs) ?? undefined,
    });
  }, [breadcrumbs, pageContentId, setPage]);

  React.useEffect(() => {
    setPreviewId(previewId);
  }, [previewId, setPreviewId]);

  React.useEffect(() => {
    setArticleId(articleId);
  }, [articleId, setArticleId]);

  React.useEffect(() => {
    if (!!articleId && !!biRequest && isLoaded) {
      const timeout = setTimeout(() => biRequest(requestBiData), 500);

      return () => timeout && clearTimeout(timeout);
    }

    return undefined;
  }, [articleId, biRequest, isLoaded]);

  /* istanbul ignore next */
  const onPathChangedCb = React.useCallback(() => {
    setCookie();
  }, [setCookie]);

  usePathChanged(onPathChangedCb);

  return <ProvokeAdBlock />;
}
