import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import React from 'react';

import type { HtzUserType } from '@haaretz/s-types';

type InjectSectionProps = {
  isMobile: boolean;
  userType: string[] | null | undefined;
  clientUserType: HtzUserType;
  numberOfVisits: number | null | undefined;
};

type UseBackToSectionProps = {
  enabled: boolean | null | undefined;
  sectionUrl: string | null | undefined;
  numberOfVisits: number | null | undefined;
  userType: string[] | null | undefined;
};

const BACK_TO_SECTION_COUNT_KEY = 'backToSectionCount';
const BACK_TO_SECTION_HASH = '#!/backToSection';

/** NOTE:
 * Chrome and Safari have taken measures against hijacking user's browsing history, making this hook unreliable.
 * User needs to interact with the page in order for this to work. The amount of interaction varies between browsers
 * and may change in the future.
 *
 * Relevant Safari info:
 * [Ignore history items added by JS without user interaction when navigation back/forward](https://bugs.webkit.org/show_bug.cgi?id=241885).
 *
 * Relevant Chrome info:
 * [History manipulation intervention](https://issues.chromium.org/issues/40603265).
 */
export default function useBackToSection({
  enabled,
  sectionUrl,
  numberOfVisits,
  userType,
}: UseBackToSectionProps) {
  const platform = usePlatform();
  const user = useUser('cookieValue');
  const isMobile = platform === 'mobile';
  const clientUserType = user.userType;

  React.useEffect(() => {
    function handlePopState() {
      if (document.location.hash === BACK_TO_SECTION_HASH) {
        window.history.replaceState(
          null,
          '',
          `${document.location.pathname}${document.location.search}`
        );

        // HACK: without this, Firefox enters a loop when navigating back:
        // article page — recommendations page - article page - recommendations page etc
        setTimeout(
          () =>
            document.location.replace(
              `${sectionUrl}?htm_source=site&htm_medium=link&htm_campaign=back_from_google&htm_content=article_page`
            ),
          0
        );
      }
    }

    function replaceHistory() {
      window.history.replaceState(
        null,
        '',
        `${document.location.pathname}${document.location.search}${BACK_TO_SECTION_HASH}`
      );
      window.history.pushState(
        null,
        '',
        `${document.location.pathname}${document.location.search}`
      );
      window.addEventListener('popstate', handlePopState, false);
    }

    function handleInject() {
      if (!enabled && !!sectionUrl) return;
      setTimeout(() => incrementBackToSectionCounter(), 1000);
      if (!shouldInjectSection({ isMobile, userType, clientUserType, numberOfVisits })) return;
      replaceHistory();
    }

    handleInject();

    return () => {
      window.removeEventListener('popstate', handleInject);
    };
  }, [clientUserType, enabled, isMobile, numberOfVisits, sectionUrl, userType]);
}

function incrementBackToSectionCounter() {
  const counter = getBackToSectionCounter() + 1;
  window.localStorage.setItem(BACK_TO_SECTION_COUNT_KEY, JSON.stringify(counter));
}

function shouldInjectSection({
  isMobile,
  userType,
  clientUserType,
  numberOfVisits,
}: InjectSectionProps) {
  const referrer = document.referrer;
  const correctReferrer =
    document.location.search.includes('utm_source=mailchimp') ||
    referrer.includes('google.') ||
    referrer.includes('facebook.');

  const correctUser = !!clientUserType && !!userType && userType.includes(clientUserType);

  if (!correctReferrer || !isMobile || !correctUser) {
    return false;
  }

  const frequency = numberOfVisits;
  const count = getBackToSectionCounter();
  const correctFrequency =
    count && count > 0 && frequency && frequency > 0 && count % frequency === 0;

  if (correctFrequency) {
    window.localStorage.setItem(BACK_TO_SECTION_COUNT_KEY, '0');
  } else {
    return false;
  }
  return true;
}

function getBackToSectionCounter(): number {
  const count = window.localStorage.getItem(BACK_TO_SECTION_COUNT_KEY);
  return count ? Number(JSON.parse(count)) : 0;
}
